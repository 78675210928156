import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Divider } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Box } from "@material-ui/core";
import FlagIcon from "../../../common/FlagIcon";
import { CustomSlider } from './CustomSlider';
import CustomButton from "../CustomButtonsWebsite/CustomButton";
import { Hidden } from "@material-ui/core";
import { InputBase } from "@material-ui/core";
import NumberFormat from '../../../common/NumberFormat';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '940px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  header: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    maxWidth: '220px',
    padding: '15px 10px',
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    top: '-48px',
    fontSize: '20px',
  },
  paper: {
    width: "100%",
    border: '1px solid #CACCD5',
    borderRadius: '10px',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 4)
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 2)
    }
  },
  value: {
    fontFamily: 'Inter',
    color: '#626680',
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '26px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    }
  },
  sendingValue: {
    fontSize: "20px",
    color: theme.palette.primary.dark,
    fontWeight: "500",
    minWidth: "150px",
    display: "inline-block",
  },
  sendingLabel: {
    fontSize: "18px",
    color: theme.palette.primary.light,
  },
  margin: {
    height: theme.spacing(3)
  },
  itemTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    color: "#31517A",
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      lineHeight: '26px',
    }
  },
  labelTitle: {
    fontFamily: 'Inter',
    fontWeight: 500,
    color: '#626680',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(3),
      fontSize: '22px',
      lineHeight: '36px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      fontSize: '20px',
      lineHeight: '30px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    }
  },
  itemValue: {
    color: '#626680',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '36px',
      fontWeight: 600,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: 500
    }
  },
  helpIcon: {
    float: 'right',
    cursor: 'pointer',
    color: '#fff',
    paddingRight: '10px',
    textDecoration: 'none',
    fontWeight: 'bold'
  },
  itemPaper: {
    textAlign: "center",
    margin: 'auto',
    border: '1px solid #CACCD5',
    borderRadius: '4px'
  },
  itemPaperContainer: {
    "@media (max-width: 599px)": {
      paddingBottom: '20px',
    },
  },
  innerContainer: {
    display: 'flex',
  },
  sliderContainer: {
    paddingTop: '20px',
  },
  input: {
    [theme.breakpoints.up('sm')]: {
      height: '60px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '52px',
    },
    width: '100%',
    border: '1px solid #CACCD5',
    borderRadius: '4px',
    padding: '0 16px'
  },
  fixedValue: {
    color: '#31517A',
    fontWeight: 500,
    fontSize: '18px',
    width: '100%',
    minWidth: '75px',
    marginRight: theme.spacing(2)
  },
  currency: {
    fontSize: '18px',
  },
  flagIcon: { marginRight: 10 },
  verticalDivider: {
    width: '1px',
    background: 'transparent',
    borderLeft: '1px solid #CACCD5',
    marginRight: theme.spacing(2)
  },
  divider: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1, 0),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
    },
    background: 'transparent',
    borderBottom: '1px solid #CACCD5'
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      height: '60px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '52px',
    }
  }
}));

const FeeItemList = ({ value, title }) => {
  const classes = useStyles();
  let valueText = value > 0 ? `${value} CAD` : 'Free';
  let textStyle = value > 0 ? { color: "#626680" } : { color: "#2EBC31" };
  return (
    <Grid item xs={12}>
      <Box display='flex'>
        <Box flexGrow={1}>
          <Typography className={classes.itemTitle}>{title}</Typography>
        </Box>
        <Box>
          <div style={textStyle}><Typography className={classes.itemValue}>{valueText}</Typography></div>
        </Box>
      </Box>
    </Grid>
  )
}

let marks = [500, 1000, 1500, 2000, 2500].map(e => ({ value: e }));

export default function DebitCalculator() {
  const content = {
    debit: 'Debit card fee:'
  }
  const classes = useStyles();
  const [fees, setFees] = React.useState({
    debit: 2.99
  });
  const [sliderValue, setSliderValue] = React.useState(500);
  const [resultAmount, setResultAmount] = React.useState();

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleInputChange = event => {
    const value = event.target.value.includes(',') ? event.target.value.replace(',', '') : event.target.value
    if (Number(value) > 3000) {
      setResultAmount(value)
      setSliderValue(10)
    }
    else {
      setResultAmount(value)
      setSliderValue(value === "" ? "" : Number(value));
    }
  };

  const calculateFees = () => {
    let amount = `${Number(sliderValue)}.00`;
    if (amount !== resultAmount) setResultAmount(amount);

    let newFees = {
      debit: (amount <= 100 ? 2.99 : amount <= 300 ? 4.99 : amount <= 500 ? 6.99 : amount <= 1000 ? 8.99 : amount <= 1500 ? 14.99 : amount <= 2000 ? 19.99 : amount <= 2500 ? 24.99 : 29.99).toFixed(2)
    };
    setFees(newFees);
  };

  React.useEffect(() => {
    calculateFees();
  }, [sliderValue]);

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6}>
            <Typography
              variant='h3'
              className={classes.labelTitle}>You are sending:</Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box display='flex' className={classes.input}>
              <Box display='flex' alignItems='center' flexGrow={1}>
                <InputBase
                  id='amount'
                  aria-label='Amount'
                  placeholder={'0.00'}
                  inputComponent={NumberFormat}
                  inputProps={{ decimalScale: 2, thousandSeparator: true }}
                  value={resultAmount}
                  classes={{ input: classes.fixedValue }}
                  onChange={handleInputChange}></InputBase>
              </Box>
              <Box display='flex' alignItems='center'>
                <Divider orientation='vertical' classes={{ root: classes.verticalDivider }} />
                <FlagIcon
                  code={'CA'}
                  className={classes.flagIcon}
                />
              </Box>
              <Box display='flex' alignItems='center'>
                <Typography variant='h3' className={classes.currency}>{'CAD'}</Typography>
              </Box>
            </Box>

          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt={4} mb={1}>
              <CustomSlider
                aria-label="Slider"
                value={typeof sliderValue === "number" ? sliderValue : 0}
                onChange={handleSliderChange}
                aria-labelledby="fee-input-slider"
                defaultValue={500}
                marks={marks}
                min={10}
                max={3000}
                valueLabelDisplay="off"
              />
            </Box>
            <Hidden smDown>
              <Box display='flex' mb={1}>
                <Box flexGrow={1}>
                  <Typography className={classes.value}>{'$10'}</Typography>
                </Box>
                <Box>
                  <Typography className={classes.value}>{'$3,000'}</Typography>
                </Box>
              </Box>
            </Hidden>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <FeeItemList value={fees.debit} title={content.debit} />
          <Grid item xs={12}>
            <CustomButton href="/signup" className={classes.button} type="green">Start Sending</CustomButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}