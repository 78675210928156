import React, { useState } from 'react';
import { Box, FormHelperText, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FlagIcon from '../../../common/FlagIcon';
import { Button, TextField } from '../../../common';
import { numbersAllowed } from '../../../../shared/regex';

const useStyles = makeStyles(theme => ({
    box: {
        marginRight: theme.spacing(2),
        width: '115px'
    },
    root: {
        border: '1px solid rgba(0, 0, 0, 0.26)',
        backgroundColor: '#F8F8F8',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
    },
    field: {
        width: '100%',
        height: '48px'
    },
    code: {
        paddingLeft: 2,
        color: '#797979',
        display: 'inline-block',
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1, 0, 2)
    },
    errorText:{
        color: '#AD1206',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
}));

const RecipientPhoneNumber = ({
    t,
    onContinue,
    countryCode,
    flow,
    recipientPhoneNumber,
    setRecipientPhoneNumber,
    countryCallingCode,
    error,
    setError,
    buttonLoading,
    disabled,
    autoFocus = true,
    clear
}) => {
    const classes = useStyles();
    const [phoneNumber, setPhoneNumber] = useState(recipientPhoneNumber || '');

    const onChange = ({ target }) => {
        setPhoneNumber(target.value);
        if (error && setError) setError(null);
        if (setRecipientPhoneNumber) setRecipientPhoneNumber(target.value !== "" ? target.value.trim() : null);
        if (clear) clear();
    }

    return (
        <>
            <Box className={onContinue ? 'flowBox' : ''}>
                <div className='flowLabel'>{t(`${flow}:recipient_details.phone_number`)}</div>
                <Box display='flex' style={{ width: '100%' }}>
                    <Box className={classes.box}>
                        <Grid container className={classes.root} style={{border: error ?  '1.5px solid #FF4B55' : '1px solid #CACCD5'}}>
                            <Grid item className={classes.item}>
                                <FlagIcon code={countryCode || 'CA'} />
                            </Grid>
                            <Grid item xs className={classes.code}>
                                <Typography style={{ fontSize: '16px' }}>
                                    {`+ ${countryCallingCode || 1}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box flexGrow={1}>
                        <TextField
                            value={phoneNumber}
                            onChange={onChange}
                            margin="normal"
                            variant="outlined"
                            type="number"
                            autoFocus={autoFocus}
                            error={error}
                            hideErrorMessage
                            validationRegex={numbersAllowed}
                            InputProps={disabled ? { disabled } : null}
                            placeholder={flow === 'mobile-top-up'? t(`${flow}:recipient_details.enter_recipient_phone_number`) : null}
                        />
                    </Box>
                </Box>
                {error && <FormHelperText className={classes.errorText}>{error}</FormHelperText>}
            </Box>
            {onContinue && <Button
                loading={buttonLoading}
                fullWidth
                disabled={!phoneNumber || phoneNumber === '' || error || buttonLoading}
                className='flowButton'
                onClick={onContinue}
                variant="contained"
                color="primary"
                size="large"
            >
                {t(`${flow}:recipient_details.next_step`)}
            </Button>}
        </>
    )
}

export default RecipientPhoneNumber;