import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { container } from '../../globalStyles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Markdown from 'markdown-to-jsx';

const useStyles = makeStyles(theme => ({
    topBar: {
        width: '100%',
        minHeight: '60px',
        background: 'linear-gradient(90deg, rgba(111,125,196,1) 0%, rgba(167,110,180,1) 25%, rgba(136,118,189,1) 66%, rgba(167,110,180,1) 100%)',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        }
    },
    container: {
        ...container,
        padding: theme.spacing(2, 0)
    },
    text: {
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '24px',
        }

    },
    link: {
        fontFamily: 'Heebo',
        color: '#fff',
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: 500,
        textDecoration: 'underline',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '10px',
            fontSize: '14px',
            lineHeight: '24px',
        }
    }
}));

const TopBar = ({
    text,
    actionText,
    actionUrl
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={classes.topBar}>
            <Box className={classes.container} display='flex'>
                {text && <Box flexGrow={1}>
                    <Typography className={classes.text}>
                        <Markdown children={text} />
                        {isMobile && actionUrl && actionText && 
                            <Link href={actionUrl} className={classes.link}>{actionText}</Link>}
                    </Typography>
                </Box>}
                {!isMobile && actionUrl && actionText && <Box>
                    <Link href={actionUrl} className={classes.link}>{actionText}</Link>
                </Box>}
            </Box>
        </Box>
    )
}

export default TopBar;