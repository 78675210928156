import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { container } from '../../globalStyles';
import { Box, Grid, Typography } from '@material-ui/core';
import CustomButton from '../CustomButtonsWebsite/CustomButton';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@material-ui/core';
import FlagIcon from '../../../common/FlagIcon';
import { interpolate, parseArrFromString } from '../../../../shared/utility';
import Skeleton from '@material-ui/lab/Skeleton';
import Markdown from 'markdown-to-jsx';
import { setRedirectSignupCookie } from '../../../../shared/cookie-handler';
import { fetchCountriesMulticurrencyRest } from '../../../../redux/system/actions';
import rbTheme from '../../../../remitbee/theme/Theme';

const useStyles = makeStyles(theme => ({
    container: {
        ...container,
        marginTop: theme?.spacing(20)
    },
    blueCard: {
        width: '100%',
        background: '#1160F7',
        borderRadius: '32px',
        marginBottom: theme?.spacing(10),
        [theme?.breakpoints?.up('xs')]: {
            padding: theme?.spacing(10, 8, 8),
        },
        [theme?.breakpoints?.down('xs')]: {
            padding: theme?.spacing(6, 2),
        },
    },
    whiteCard: {
        background: '#fff',
        borderRadius: '32px',
        border: '2px solid #1160F71E',
        marginBottom: theme?.spacing(10),
        [theme?.breakpoints?.up('xs')]: {
            padding: theme?.spacing(10, 8, 0),
        },
        [theme?.breakpoints?.down('xs')]: {
            padding: theme?.spacing(1, 2, 0),
        },
    },
    chip: {
        [theme?.breakpoints?.up('sm')]: {
            fontSize: '20px',
            lineHeight: '30px',
        },
        [theme?.breakpoints?.down('sm')]: {
            fontSize: '14px',
            lineHeight: '22px'
        },
    },
    tag: {
        color: '#1160F7',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontFamily: 'Inter',
        background: '#F1F6FF',
        borderRadius: 4,
        padding: theme?.spacing(1, 3),
    },
    title: {
        [theme?.breakpoints?.up('sm')]: {
            fontSize: '64px',
            lineHeight: '90px',
        },
        [theme?.breakpoints?.down('sm')]: {
            fontSize: '32px',
            lineHeight: '48px',
        },
        fontWeight: 600,
        fontFamily: 'Inter',
        marginTop: theme?.spacing(4)
    },
    subtitle: {
        color: '#626680',
        [theme?.breakpoints?.up('sm')]: {
            fontSize: '22px',
            lineHeight: '37px',
        },
        [theme?.breakpoints?.down('sm')]: {
            fontSize: '18px',
            lineHeight: '30px',
        },
        fontFamily: 'Inter',
        marginTop: theme?.spacing(2)
    },
    button: {
        margin: theme?.spacing(8, 0),
        display: 'flex',
        justifyContent: 'center'
    },
    blueRateWeb: {
        width: 'fit-content',
        background: 'rgba(17, 96, 247, 0.8)',
        borderRadius: '10px',
        padding: theme?.spacing(3, 4),
        [theme?.breakpoints?.up('sm')]: {
            marginTop: theme?.spacing(4)
        }
    },
    blueRate: {
        width: 'fit-content',
        background: 'rgba(17, 96, 247, 0.8)',
        borderRadius: '10px',
        padding: theme?.spacing(3, 4),
        marginTop: theme?.spacing(4)
    },
    rate: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme?.breakpoints?.up('sm')]: {
            fontSize: '32px',
            lineHeight: '60px',
        },
        [theme?.breakpoints?.down('sm')]: {
            fontSize: '20px',
            lineHeight: '39px',
        },
        fontFamily: 'Inter',
        fontWeight: 500,
        color: '#31517A'
    },
    smallRate: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
        lineHeight: '28px',
        fontFamily: 'Inter',
        fontWeight: 500,
    },
    image: {
        width: '100%'
    },
    whiteCardImage: {
        width: '100%',
        [theme?.breakpoints?.down('sm')]: {
            marginTop: theme?.spacing(5)
        }
    },
    imageContainer: {
        width: '100%',
        marginBottom: theme?.spacing(10)
    },
    flagIcon: {
        margin: theme?.spacing(0, 2),
        width: '49px',
        height: '35px',
        borderRadius: '5px'
    },
    flagIconSmall: {
        margin: theme?.spacing(0, 1),
        width: '35px',
        height: '25px',
        borderRadius: '5px'
    },
    topChip: {
        background: '#F1F6FF',
        borderRadius: '4px',
        color: '#31517A',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontFamily: 'Inter',
        padding: theme?.spacing(1, 3),
        [theme?.breakpoints?.up('sm')]: {
            fontSize: '20px',
            lineHeight: '30px',
        },
        [theme?.breakpoints?.down('sm')]: {
            fontSize: '14px',
            lineHeight: '22px'
        },
        display: 'flex',
        maxWidth: 'fit-content',
        alignItems: 'center',
        columnGap: theme?.spacing(1)
    },
    blueTopChip: {
        background: '#31517A',
        borderRadius: '4px',
        color: '#fff',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontFamily: 'Inter',
        padding: theme?.spacing(1, 3),
        [theme?.breakpoints?.up('sm')]: {
            fontSize: '20px',
            lineHeight: '30px',
        },
        [theme?.breakpoints?.down('sm')]: {
            fontSize: '14px',
            lineHeight: '22px'
        },
        display: 'flex',
        maxWidth: 'fit-content',
        alignItems: 'center',
        columnGap: theme?.spacing(1)
    },
    chipList: {
        display: 'flex',
        gridGap: theme?.spacing(2),
        justifyContent: 'center'
    },
    blueChipList: {
        display: 'flex',
        gridGap: theme?.spacing(2),
        position: 'relative',
        justifyContent: 'flex-end',
        [theme?.breakpoints?.up('sm')]: {
            marginTop: '-250px'
        },
        [theme?.breakpoints?.down('sm')]: {
            marginTop: '-200px',
            marginLeft: theme?.spacing(10)
        }
    },
    arrow: {
        [theme?.breakpoints?.up('sm')]: {
            margin: theme?.spacing(0, 1.5),
            transform: 'rotate(0turn)'
        },
        [theme?.breakpoints?.down('sm')]: {
            width: '35px',
            margin: theme?.spacing(2, 1.5),
            transform: 'rotate(0.25turn)'
        }
    },
    subtitleSkeleton: {
        marginTop: theme?.spacing(2)
    }
}));

const PromotionalHero = ({
    format,
    selectedCountry,
    selectedCurrency,
    tag,
    topChip,
    topChipIcon,
    chipList,
    title,
    subtitle,
    actionText,
    actionType,
    actionUrl,
    desktopImage,
    mobileImage,
    imageAlt
}) => {
    const classes = useStyles();
    const router = useRouter();
    const isMobile = useMediaQuery(`(max-width: ${rbTheme?.breakpoints?.sm}px)`);
    const isNotTablet = useMediaQuery(`(max-width: ${rbTheme?.breakpoints?.md}px)`);
    const [listItems] = useState(chipList ? parseArrFromString(chipList) : null);
    const [specialRate, setSpecialRate] = useState({});
    const [finalSubtitle, setFinalSubtitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const fetchPromotionalRates = async () => {
            // const { data } = await getTransferRates({ where: { country_to_id: +selectedCountry, promo_page: true } });
            // if (data) {
            //     const { TransferRatesOnline } = data;
            //     if (TransferRatesOnline && TransferRatesOnline.length) {
            //         setSpecialRate({
            //             ...selectedCountryRate,
            //             special_rate: selectedCountryRate.special_rate ? (selectedCountryRate.special_rate?.includes('.') ? selectedCountryRate.special_rate : parseFloat(selectedCountryRate.special_rate).toFixed(2)) : selectedCountryRate.rate,
            //             special_rate_adjustment: selectedCountryRate.special_rate_adjustment?.includes('.') ? selectedCountryRate.special_rate_adjustment : parseFloat(selectedCountryRate.special_rate_adjustment).toFixed(2),
            //             special_rate_transfer_amount_limit: selectedCountryRate.special_rate_transfer_amount_limit?.includes('.') ? selectedCountryRate.special_rate_transfer_amount_limit : parseFloat(selectedCountryRate.special_rate_transfer_amount_limit).toFixed(2),
            //         });
            //         const final = interpolate(subtitle, selectedCountryRate);
            //         setFinalSubtitle(final);
            //         setLoading(false);
            //     }
            // } else setLoading(false);
            const rates = await fetchCountriesMulticurrencyRest();

            if (rates && rates.length) {
                const selectedCountryRateList = rates.filter(r => r && r.country_id === +selectedCountry);
                let selectedCountryRate = null;
                if (selectedCountryRateList && selectedCountryRateList.length) {
                    selectedCountryRate = selectedCountryRateList.find(r => r && r.currency_code === selectedCurrency) || selectedCountryRateList[0];
                }
                if (selectedCountryRate && Object.keys(selectedCountryRate).length) {
                    setSpecialRate({
                        ...selectedCountryRate,
                        special_rate: selectedCountryRate.special_rate ? (selectedCountryRate.special_rate?.includes('.') ? selectedCountryRate.special_rate : parseFloat(selectedCountryRate.special_rate).toFixed(2)) : selectedCountryRate.rate,
                        special_rate_adjustment: selectedCountryRate.special_rate_adjustment?.includes('.') ? selectedCountryRate.special_rate_adjustment : parseFloat(selectedCountryRate.special_rate_adjustment).toFixed(2),
                        special_rate_transfer_amount_limit: selectedCountryRate.special_rate_transfer_amount_limit?.includes('.') ? selectedCountryRate.special_rate_transfer_amount_limit : parseFloat(selectedCountryRate.special_rate_transfer_amount_limit).toFixed(2),
                    });
                    const final = interpolate(subtitle, selectedCountryRate);
                    setFinalSubtitle(final);
                }
                setLoading(false);
            } else setLoading(false);
        };

        if (selectedCountry) fetchPromotionalRates();

    }, [selectedCountry])

    const handleButtonClick = async () => {
        if (actionUrl && actionUrl.includes('signup')) {
            await setRedirectSignupCookie(router.asPath)
        }
        if (actionUrl) router.push(actionUrl);
    }

    const promotionalRate = (variant, small) => {
        const { special_rate, currency_code, iso2, rate } = specialRate;

        return (
            <Box display={!isNotTablet ? 'block' : 'flex'} alignItems='center' justifyContent='center' mt={small ? 0 : !isNotTablet ? 3 : 6}>
                <Box>
                    <Typography align='center' className={small ? classes.smallRate : classes.rate} style={variant ? { color: variant } : null}>
                        <FlagIcon code="CA" className={small ? classes.flagIconSmall : classes.flagIcon} />
                        <span style={{ fontSize: !isNotTablet || small ? '30px' : '55px' }}>1.00</span> CAD
                    </Typography>
                </Box>
                <Box display='flex' justifyContent='center'>
                    <img width={small ? '35px' : '58px'} className={classes.arrow} src='/new/rate-arrow.svg' alt='Arrow' />
                </Box>
                <Box>
                    {loading ? (
                        <Box display='flex' gridColumnGap='8px' alignItems='center'>
                            <Skeleton variant="rect" className={small ? classes.flagIconSmall : classes.flagIcon} />
                            <Skeleton width={110} height={28} />
                        </Box>
                    ) : (
                        <Typography align='center' className={small ? classes.smallRate : classes.rate} style={variant ? { color: variant } : null}>
                            <FlagIcon code={iso2} className={small ? classes.flagIconSmall : classes.flagIcon} />
                            <span style={{ fontSize: !isNotTablet || small ? '30px' : '55px' }}>{special_rate ? special_rate : rate}</span> {currency_code}
                        </Typography>
                    )}
                </Box>
            </Box>
        )
    }

    if (format === 'largeImage') return (
        <Grid className={classes.container}>
            {tag && <Typography align='center' className={classes.chip}>
                <span className={classes.tag}>{tag}</span>
            </Typography>}
            {title && <Typography variant='h1' align='center' className={classes.title}>{title}</Typography>}
            {finalSubtitle && <Typography variant='h2' align='center' className={classes.subtitle}>
                <Markdown>{finalSubtitle}</Markdown>
            </Typography>}

            {specialRate && promotionalRate()}

            {actionText && actionUrl && <div className={classes.button}>
                <CustomButton type={actionType} onClick={handleButtonClick}>{actionText}</CustomButton>
            </div>}
            {!isMobile && desktopImage && (
                <div className={classes.imageContainer}>
                    <img src={`${desktopImage}?${new Date().getTime()}`} alt={imageAlt} className={classes.image} />
                </div>
            )}
            {isMobile && mobileImage && (
                <div className={classes.imageContainer}>
                    <img src={mobileImage} alt={imageAlt} className={classes.image} />
                </div>
            )}
        </Grid>
    )

    if (format === 'blueCard') return (
        <Grid container className={classes.container}>
            <Grid item className={classes.blueCard}>
                {topChip && <Box display='flex' justifyContent='center'>
                    <span className={classes.topChip}>
                        {topChipIcon && <img src={topChipIcon} alt='Chip icon' />}
                        {topChip}
                    </span>
                </Box>}

                {title && <Typography variant='h1' align='center' className={classes.title} style={{ color: '#fff' }}>{title}</Typography>}

                {finalSubtitle && <Typography variant='h2' align='center' className={classes.subtitle} style={{ color: '#fff' }}>
                    <Markdown>{finalSubtitle}</Markdown>
                </Typography>}

                {specialRate && promotionalRate('white')}

                {actionText && actionUrl && <div className={classes.button}>
                    <CustomButton type={actionType} onClick={handleButtonClick}>{actionText}</CustomButton>
                </div>}

                {listItems && <Box className={classes.chipList} display='flex' flexWrap="wrap">
                    {listItems.map((item, index) => (
                        <span className={classes.topChip} style={{ borderRadius: 200 }} key={index}>
                            {item.icon && <img src={item.icon} alt='Chip icon' />}
                            {item.text}
                        </span>
                    ))}
                </Box>}
            </Grid>
        </Grid>
    )

    return (
        <Grid container className={classes.container}>
            <Grid item container className={classes.whiteCard}>
                <Grid item xs={12} md={6}>
                {topChip && <Box display='flex' justifyContent='left'>
                    <span className={classes.topChip}>
                        {topChipIcon && <img src={topChipIcon} alt='Chip icon' />}
                        {topChip}
                    </span>
                </Box>}
                    {isNotTablet && <Box className={classes.blueRateWeb}>
                        {specialRate && promotionalRate('white', true)}
                    </Box>}
                    {title && <Typography variant='h1' className={classes.title} align={isMobile ? 'center' : 'left'}>{title}</Typography>}

                    {finalSubtitle !== '' && <Typography variant='h2' align={isMobile ? 'center' : 'left'} className={classes.subtitle}>
                        <Markdown>{finalSubtitle}</Markdown>
                    </Typography>}

                    {finalSubtitle === '' && (
                        <Box className={classes.subtitleSkeleton}>
                            {Array.from(Array(4)).map((item, index) => (
                                <Skeleton height={30} key={index} />
                            ))}
                        </Box>
                    )}

                    {!isNotTablet && <Box display='flex' justifyContent='center'>
                        <Box className={classes.blueRate}>
                            {specialRate && promotionalRate('white', true)}
                        </Box>
                    </Box>}

                    {actionText && actionUrl && <>
                        <Box py={6} display='flex' justifyContent={isMobile ? 'center' : 'flex-start'}>
                            <CustomButton type={actionType} onClick={handleButtonClick}>{actionText}</CustomButton>
                        </Box>
                    </>}
                </Grid>
                <Grid item xs={12} md={6}>
                    {desktopImage && (
                        <Box display='flex' alignItems="flex-end" pl={2} style={{ height: '100%' }}>
                            <img
                                src={`${desktopImage}?${new Date().getTime()}`}
                                alt={imageAlt}
                                className={classes.whiteCardImage}
                                loading='lazy'
                                onLoad={() => setImageLoaded(true)} />
                        </Box>
                    )}
                    {listItems && imageLoaded && <Box className={classes.blueChipList} display='flex' flexWrap="wrap">
                        {listItems.map((item, index) => (
                            <span className={classes.blueTopChip} style={{ borderRadius: 200 }} key={index}>
                                {item.icon && <img src={item.icon} alt='Chip icon' />}
                                {item.text}
                            </span>
                        ))}
                    </Box>}
                </Grid>
            </Grid>
        </Grid>
    )
}


export default PromotionalHero;
