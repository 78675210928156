import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import Share from "../../../common/Share";
import Subscribe from "../../../common/Subscribe";
import styles from "./styles";
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import { getImageAlt } from "../../../../shared/utility";

const useStyles = makeStyles(styles);

function HeroTitle({ title, text, titleLogo, titleLogoClass, logo, bottom, children, className = {}, isActionTextHidden, styles, subscribeSection, shareSection }) {
  const classes = useStyles();
  const isNotMobile = useMediaQuery(theme => theme.breakpoints.up('md'));
  let isActionTextVisible = true;
  const rootStyle = {}
  if(!isNotMobile) rootStyle.textAlign = 'center';
 rootStyle.paddingBottom = '50px';
  if(isActionTextHidden) {
    isActionTextVisible = false;
  }
  return (
    <div style={rootStyle}>
      <h1 id="title" className={bottom ? classes.bottomTitle : styles ? styles.leftSectionTitle : classes.title} >
        {title}
        {titleLogo && <img src={titleLogo} alt={getImageAlt(titleLogo)} className={titleLogoClass ? titleLogoClass : null}/>}
      </h1>
      {text && <div className={bottom ? classes.contentBottom : styles ? styles.leftSectionText : classes.content + " " + className}>{text}</div>}
      <Grid container spacing={1} columns={{ xs: 1, sm: 2, md: 3 }}>
      {logo && logo.map((item, index) => ( 
        <Grid item xs className={isNotMobile ? classes.gridClass : null} key={index} >    
          <Box>
            <img src={`${item.link}`} alt={`${item.alt}`} />
          </Box>
        </Grid>
      ))}
      </Grid>
      {isActionTextVisible ? children : null}
      {subscribeSection && <Subscribe {...subscribeSection} />}
      {shareSection && <Share />}
    </div>
  )
}

HeroTitle.propTypes = {
  title: PropTypes.any,
  content: PropTypes.any
}

export default HeroTitle;
