import { container } from '../../globalStyles';
const styles = theme => ({
  container,
  alignContainer: {
    alignItems: 'center',
  },
  title: {
    color: "white",
    margin: "1.75rem 0 0.875rem",
    textDecoration: "none",
    fontWeight: "300",
    fontSize: "3rem",
    opacity: "1",
    lineHeight: "1.2",
    paddingBottom: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: "35px",
      textAlign: 'center',
      margin: '-20px auto',
    },
    "@media (max-width: 320px)": {
      fontSize: "28px",
    },
    "@media (max-width: 280px)": {
      fontSize: "35px",
    },
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  boldText: {
    fontWeight: "700"
  },
  hero: {
    background: '#1160F7',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      padding: "140px 0 60px 0",
      background: '#1160F7',
    },
    [theme.breakpoints.up('md')]: {
      padding: "140px 0 80px 0",
      background: '#1160F7',
    },
  },
  bottomTitle: {
    fontWeight: 600,
    fontFamily: 'Inter',
  },
  content: {
    color: '#fff',
    fontWeight: '400',
    paddingTop: '24px',
    paddingBottom: '24px'
  },
  contentBottom: {
    fontWeight: 400,
    color: '#626680',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      lineHeight: '44px',
      fontSize: '24px',
    },
    [theme.breakpoints.down('md')]: {
      lineHeight: '36px',
      fontSize: '18px',
    },
  },
  gridClass: {
    maxWidth: '150px',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(3),
    }
  },
  shortHero: {
    padding: "140px 0 220px 0 !important",
    height: '450px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '200px'
    },
  },
  subtitle: {
    textAlign: 'center',
    color: 'white',
    fontWeight: '300',
    fontSize: '14px'
  },
  heroButton: {
    border: '1px solid #FFFFFF',
    fontSize: '1rem',
    padding: '0.8rem 2rem',
    background: 'transparent',
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#1160F7"
    }
  },
  rightSectionStyle: {
    width: '100%',
    zIndex: '20',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  arrow: {
    marginTop: '8px',
    marginBottom: '-24px'
  }
})

export default styles;