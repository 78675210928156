import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styles from "./styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(styles);

function Hero({ leftSection, rightSection, backgroundColor, backgroundImage, children, className = {}, short, topSection, middleSection, bottomSection, isVertical, showBottomArrow }) {
  const classes = useStyles();
  const arrowRef = useRef();
  const heroStyle = {};
  if (backgroundImage) {
    heroStyle.background = `url(${backgroundImage}) no-repeat center`;
    heroStyle.backgroundSize = 'cover';
  }
  if(backgroundColor){
    heroStyle.backgroundColor = backgroundColor;
  }
  heroStyle.paddingRight = '0px !important';
  const isShort = short ? classes.shortHero : '';

  const handleScroll = () => {
    if(typeof window !== 'undefined') window?.scrollTo({ top: arrowRef.current.getBoundingClientRect().top + 100, behavior: 'smooth' })
  }

  return (
    <div className={classes.hero + " " + className + " " + isShort} style={heroStyle} >
      {isVertical ? (
        <Grid container className={classes.container}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {topSection}
          </Grid>
          {middleSection && <Grid item xs={12}>
            {middleSection}
          </Grid>}
          {bottomSection && <Grid item xs={12}>
            {bottomSection}
          </Grid>}
        </Grid>)
        : (
          <Grid container alignItems='center' className={classes.container} >
            <Grid item xs={12} md={6}>
              {leftSection}
            </Grid>
            <Grid item xs={12} md={6} className={classes.rightSectionStyle}>
              {rightSection}
            </Grid>
          </Grid>)}

      <div className={classes.container}>
        {children}
      </div>

      {showBottomArrow && (
        <Box display="flex" justifyContent="center" className={classes.arrow}>
          <IconButton size='small' onClick={handleScroll}>
            <KeyboardArrowDownIcon fontSize="large" color="primary" ref={arrowRef}  />
          </IconButton>
        </Box>
      )}
    </div>
  )
}

Hero.propTypes = {
  leftSection: PropTypes.any,
  rightSection: PropTypes.any,
}

export default Hero;