import React from 'react';
import { withStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Grid } from '@material-ui/core';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';

const styles = theme => ({
  container: {
    color: '#626680'
  },
  mobileContainer: {
    width: '100%',
    padding: theme.spacing(3, 2),
    '&:last-child': {
      paddingTop: theme.spacing(2)
    },
    '&:not(:last-child)': {
      padding: theme.spacing(3, 2, 0),
    },
  },
  item: {
    width: '100%',
    padding: theme.spacing(2, 3),
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#626680',
    display: 'flex',
    alignItems: 'center',
  },
  itemBox: {
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid #CACCD5',
    }
  },
  title: {
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    border: '1px solid #CACCD5',
    backgroundColor: "#31517A",
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  columnName: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '26px',
    color: '#fff',
  },
  row: {
    borderLeft: '1px solid #CACCD5',
    borderRight: '1px solid #CACCD5',
    background: '#fff',
    '&:last-child': {
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
      [theme.breakpoints.down('sm')]: {
        borderBottom: '1px solid #CACCD5',
      }
    },
    '&:nth-child(2)': {
      [theme.breakpoints.down('sm')]: {
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
      }
    },
    [theme.breakpoints.up('md')]: {
      borderBottom: '1px solid #CACCD5',
    }
  },
  first: {
    color: '#31517A',
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'Inter',
      fontWeight: 600,
      padding: theme.spacing(3, 2),
      backgroundColor: '#31517A',
      color: '#fff',
      borderTop: '1px solid #CACCD5',
      borderBottom: '1px solid #CACCD5'
    }
  },
  label: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#31517A',
  },
  rightSide: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#626680',
  },
  values: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  top: {
    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
    }
  }
});

const FeesTable = ({ rows, classes }) => {
  const tableHeader = rows[0] ? rows[0].content : null;
  const columns = tableHeader ? tableHeader.split(',') : null;
  const grid = columns ? Math.ceil(12 / columns.length) : null;
  // const style = titleColor || 'primary';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (rows) return (
    <Grid item container className={classes.container}>
      {rows.map((row, index) => (
        <Grid item container xs={12} key={index} className={index === 0 ? classes.title : classes.row}>
          {row.content && row.content.split(',').map((item, innerIndex) => (
            <React.Fragment key={innerIndex}>
              {index === 0 && (<Grid
                item
                xs={12}
                md={grid}
                key={innerIndex}
                className={innerIndex !== columns.length - 1 ? classes.itemBox : ''}>
                <Box className={classes.columnName}>
                  <Markdown children={item} />
                </Box>
              </Grid>
              )}
              {index !== 0 && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={grid}
                    key={innerIndex}
                    className={innerIndex !== columns.length - 1 ? classes.itemBox : ''}>
                    <Box className={clsx(classes.item,
                      innerIndex === 0 ? classes.first : classes.values,
                      innerIndex === 0 && index === 1 ? classes.top : '')}>
                      <Markdown children={item} />
                    </Box>
                  </Grid>
                  {innerIndex !== 0 && isMobile && (
                    <Box display='flex' className={classes.mobileContainer}>
                      <Box flexGrow={1} className={classes.label}>{columns[innerIndex]}</Box>
                      <Box className={classes.rightSide}><Markdown children={item} /></Box>
                    </Box>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </Grid>
      ))}
    </Grid>
  );
  else return null;
}

export default withStyles(styles)(FeesTable);
