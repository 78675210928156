import React, { useRef } from "react";
import { useEffect, useState } from "react";
import theme from "../../../../../remitbee/theme/Theme";
import useCustomMediaQuery from "../../../../../remitbee/customHook";
import classes from './MultiConverter.module.scss';
import { extractPredictedDate } from "../../../../../shared/calendar";
import Box from "../../../../../remitbee/components/box/Box";
import { Skeleton } from "@material-ui/lab";
import DropDownList from "../../../../../remitbee/components/dropDown/DropDownList";
import DropDownItem from "../../../../../remitbee/components/dropDown/DropDownItem";
import Typography from "../../../../../remitbee/components/typography/Typography";
import ArrowDown from "../../../../../remitbee/icons/ArrowDown";
import CheckIcon from "../../../../../remitbee/icons/CheckIcon";
import { useMediaQuery } from "@material-ui/core";


const PayWithInput = props => {
    const { onChangeSelect, availablePaymentTypes, additionalInfo, setSettlementHolidayDates, setFundingHolidayDates, t } = props;
    const [anchorel, setAnchorEl] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState({
        payment_type: '', 
        label: '',
        fees: '',
    });
    const [freeLabel, setFreeLabel] = useState(t('free'));
    const isNotMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
    const isNotTablet = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
    const dropdownRef = useRef(null);

    const setFundingTimeDraft = (paymentTypes, value) => {
        if (paymentTypes && value) {
            const paymentTypeValue = (value !== 'CURRENT_BALANCE') ? value : 'WALLET';
            const serviceTypeValue = 'MT';
            const paymentType = paymentTypes.find(({ payment_type, type }) => payment_type || type === paymentTypeValue);

            const fundingTimeInfo = extractPredictedDate({ paymentTypesDetails: paymentTypes, type: 'funding', serviceType: serviceTypeValue, paymentType: paymentType?.type || paymentTypeValue, t });
            const settlementTimeInfo = extractPredictedDate({ paymentTypesDetails: paymentTypes, type: 'settlement', serviceType: serviceTypeValue, paymentType: paymentType?.type || paymentTypeValue, t });
            fundingTimeInfo?.holidayDates?setFundingHolidayDates(fundingTimeInfo?.holidayDates): setFundingHolidayDates();
            settlementTimeInfo?.holidayDates? setSettlementHolidayDates(settlementTimeInfo?.holidayDates): setSettlementHolidayDates();
        }
    };

    useEffect(() => {
        const paymentType = selectedPaymentType?.payment_type;
        if (paymentType) setFundingTimeDraft(availablePaymentTypes, paymentType);
    }, [selectedPaymentType]);

    useEffect(() => {
        if (availablePaymentTypes && availablePaymentTypes?.length){
            // if payment type already selected, pick the values from selected type
            // else or selected type is not available in payment types, pick the default payment type
            let selected;
            if(selectedPaymentType) {
                selected = availablePaymentTypes.find(value => value.payment_type === selectedPaymentType.payment_type);
            }
            if(!selected) {
                selected = availablePaymentTypes.find(value => value.payment_type === 'INTERAC_E_TRANSFER');
            }

            setSelectedPaymentType(selected);
            onChangeSelect(selected?.timeline?.settlement_timeline?.predicted_date || selected.settlement_time);
        }

        if (typeof window !== 'undefined') window.onscroll = event => {
            setAnchorEl(null)
        }
    }, [availablePaymentTypes]);

    useEffect(() => {
        setFreeLabel(additionalInfo && additionalInfo.first_transfer_free ? t('free_first_transfer') : t('free'));
    }, [additionalInfo])

    const handleMenu = () => {
        setAnchorEl(!anchorel);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChange = paymentType => {
        setSelectedPaymentType(paymentType);
        onChangeSelect(paymentType?.timeline?.settlement_timeline?.predicted_date || paymentType.settlement_time) //set delivery date
        setAnchorEl(null);
    }

    const items = availablePaymentTypes?.length > 0 && availablePaymentTypes.map(item => {
        return (
        <DropDownItem key={item.label} onClick={() => onChange(item)}>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={8}>
            <Box display="flex" gap={4} className={classes['rb-label-box']}>
                <Box className={classes['rb-label-checkicon']}>
                {item===selectedPaymentType && <CheckIcon width={16} height={16}/>}
                </Box>
                <Typography variant="textMd" weight="medium" color={theme.palette.primary.navy} className={classes['rb-dropdownList-text']}>
                    {item.label}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" gap={4} className={classes['rb-dropdown-items']}>
            <Typography variant="textMd" align="right" weight="medium" color={theme.palette.success.dark} className={classes['rb-dropdownList-text']}>
                {item.fees && parseFloat(item.fees) !== 0 ? (`${item.fees} CAD`) : (`${freeLabel}`)}
            </Typography>
            </Box>
        </Box>
        </DropDownItem>
        );
    })

    const alignDropdownForMobileView = isNotMobile ? 28 : 0;
    const dropdownPosition = {
        position: 'absolute',
        top: dropdownRef?.current?.offsetTop + dropdownRef?.current?.offsetHeight,
        left: dropdownRef?.current?.offsetLeft - alignDropdownForMobileView,
      }

    return (
        <Box componentRef={dropdownRef} display="flex" flexDirection={isNotMobile ? "row" : "column"} gap={isNotMobile ? 0 : 4} justifyContent={"center"}>
            <Box className={classes['rb-payWithBox']}>
                    <Typography variant={'textMd'} className={classes['rb-paymentFees-payWith']} align={isNotTablet ? "center" : "left"}>
                        {t('pay_with')}
                    </Typography>
                    <div onClick={handleMenu} className={classes['rb-payTypes-heading']}>
                        {selectedPaymentType && 
                        <Typography variant={'textMd'} className={classes['rb-paymentType-text']} color={theme.palette.primary.blue} weight="medium">
                            {selectedPaymentType.label && selectedPaymentType.label.length < 15 ? selectedPaymentType.label : `${selectedPaymentType.label.substring(0, 11)}...` }
                        </Typography>}
                        <ArrowDown width={isNotMobile ? 18 : 20} height={isNotMobile ? 18 : 20} color={theme.palette.primary.blue}/>
                    </div>
            </Box>

            <Box display="flex" justifyContent={!isNotTablet ? "center" : !isNotMobile ? "flex-start" : "flex-end"} className={classes['rb-fee-box']}>
                <Typography align="center" variant={'textMd'} className={classes['rb-paymentFees-text']} weight="semibold" color={theme.palette.primary.navy}>
                    {selectedPaymentType && selectedPaymentType.fees ? parseFloat(selectedPaymentType.fees) !== 0 ? 
                    `${selectedPaymentType.fees} CAD`
                    : <Typography align={isNotTablet ? "right" : "center"} variant={isNotTablet ? "body2" : "body1"}  weight="semibold" color={theme.palette.primary.navy}>{freeLabel}</Typography>
                    : <Box display="flex" justifyContent={isNotMobile ? "center" : "flex-start"}><Skeleton variant="text" width={100} height={!isNotMobile ? 23 : 20} /></Box>}
                </Typography>
            </Box>
            <DropDownList
                ref={dropdownRef}
                style={dropdownPosition}
                primaryListClasses={classes['rb-dropdownList-class']}
                dropdownListClasses={classes['rb-dropdownList-root']}
                dropListOpen={Boolean(anchorel)} 
                setDropListOpen={handleClose} 
                primaryListItems={items} 
            />
        </Box>
    )
}

export default PayWithInput;