import groupBy from 'lodash/groupBy';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    box: {
        width: '100%',
        marginTop: theme.spacing(5)
    },
    department: {
        fontFamily: 'Poppins',
        color: '#31517A',
        textAlign: 'left',
        fontWeight: 600,
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            fontSize: '36px',
            lineHeight: '48px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '26px',
            lineHeight: '40px',
        },
    },
    name: {
        fontFamily: 'Poppins',
        color: '#31517A',
        textAlign: 'left',
        fontWeight: 600,
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
            lineHeight: '30px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '30px',
        },
    },
    role: {
        fontSize: '16px',
        lineHeight: '30px',
        color: '#626680',
        textAlign: 'left',
    },
    imageBox: {
        width: '100%',
        height: '240px',
        borderRadius: 12
    }
}));

const TeamMembers = ({ list }) => {
    const [departments, setDepartments] = useState(null);
    const classes = useStyles();
     
    useEffect(() => {
        if (list && list.length) {
            const membersByDepartment = groupBy(list, (value) => value.department);
            setDepartments(membersByDepartment);
        }
        
    }, []);

    return (
        <>
            {departments && Object.keys(departments).map((value, index) => (
                <div key={index} className={classes.box}>
                    <Typography variant='h3' className={classes.department}>{value}</Typography>
                    <Grid container spacing={4}>
                        {departments[value].map((member, idx) => (
                            <Grid item md={3} sm={4} xs={6} key={idx}>
                                {member.image && <div className={classes.imageBox} style={{ background: `url('${member.image}') center no-repeat`, backgroundSize: 'cover' }}></div>}
                                {member.name && <Typography variant='h4' className={classes.name}>{member.name}</Typography>}
                                {member.role && <Typography variant='h4' className={classes.role}>{member.role}</Typography>}
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ))}
        </>
    )
}

TeamMembers.propTypes = {
    list: PropTypes.array
};

TeamMembers.defaultProps = {
    list: []
}

export default TeamMembers;
